.container{
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 0.1fr 1fr 0.4fr;
    grid-template-rows: 0.15fr 1fr 0.5fr;
    padding-top: 5%;
    justify-content: space-between;

}

.post{
    grid-column: 2;
    grid-row:2;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.createComment{
    grid-column: 3;
    grid-row:2;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

}

.comments{
    grid-column: 2;
    grid-row:3;
    display: flex;

    flex-direction: column;
    justify-content: center;
}




::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
