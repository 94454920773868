.container{
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    padding-top: 5%;
    justify-content: space-between;

}

.form{
    grid-column: 2;
    grid-row:2;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

