.container2{
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 5%;
    justify-content: space-between;

}

.center2{

    grid-column: 2;
    display: flex;
    justify-content: space-around;
    align-items: top;
    flex-direction: column;

}

.loading{
    grid-column: 2;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;

}

::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

