.container{
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 0.5fr 1fr 0.5fr;
    grid-template-rows: 0.5fr 1fr 0.5fr;
    padding-top: 5%;
    justify-content: space-between;

}

.createPost{
    grid-column: 2;
    grid-row:2;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

